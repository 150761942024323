import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { BiUpArrowAlt } from "react-icons/bi";
import { BsFillPinFill, BsFilterLeft, BsPin, BsPinAngle, BsPinFill } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { PiArrowsDownUpFill } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import ResourceAddSuccess from "../../components/common/modals/ResourceAddSucces";
import SearchInput from "../../components/ui/SearchInput";
import { ErrorMessages } from "../../constants/Labels";
import { setShowAddSuccessPopup } from "../../redux/slice/AuthSlice";
import { deleteReource, getResourceByID, getResources, pinUnpin } from "../../services/ResourcesService";
import { formatDates } from "../../utils/common/Format";
import { showToastError, showToastSuccess } from "../../utils/common/ToastMessages";
import CreateResources from "./CreateResources";
import ResourceMetrics from "./ResourceMetrics";
import ViewResource from "./ViewResource";



export default function Resources() {
    const [showCreateResouce, setShowCreateResouce] = useState<any>(false)
    const [resourceDetials, setshowResourceDetails] = useState(false)
    const [selectedItem, setSelectedItem] = useState<any>()
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()
    const [searchTerm, setSearchTerm] = useState('')
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [orderByQiscore, setOrderByQiscore] = useState<any>('Asc');
    const [orderByUpvotes, setOrderByUpvotes] = useState('Asc')
    const [orderByBookmarksCount, setOrderByBookmarksCount] = useState('Asc')
    const [orderByPinRecords, setorderByPinRecords] = useState('Asc')


    const [orderByDate, setorderByDate] = useState('Asc')
    const [selectedOption, setSelectedOption] = useState<any>('');
    const [selectedSortOption, setSelectedSortOption] = useState<any>('');

    const dispatch = useDispatch()
    const [sortValue, setSortValue] = useState('')
    const [orderBy, setOrderBy] = useState<any>('Asc');
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const recordsPerPage = 10; // Number of records per page
    const [resourcesData, setResourcesData] = useState([])
    const [selectedUserList, setSelectedUserList] = useState('resourceLog')
    const [orderBytopicNames, setOrderBytopicNames] = useState('Asc')
    const [orderByusefulness, setOrderByusefulness] = useState('Asc')
    const [orderByFlaggedCount, setOrderByFlaggedCount] = useState('Asc')
    const [orderBytitle, setOrderBytitle] = useState('Asc')
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const [showMetricsCalender, setShowMetricsCalender] = useState(false)
    const [showEditInViewSc, setShowEditInViewSc] = useState(false)
    const [tempStartDate, setTempStartDate] = useState<any>(null);
    const [tempEndDate, setTempEndDate] = useState<any>(null);
    const [dates, setDate] = useState<any>()
    const [pinStatus, setpinStatus] = useState(false)


    const showAddSuccessPopup = useSelector((state: any) => state?.userBucket?.showSuccespopup)

    const options = [
        { value: '0', label: 'All' },
        { value: '1', label: 'Popular' },
        { value: '2', label: 'By Date' },
        { value: '3', label: 'Most Bookmarked' }
    ];
    // sortByDate options
    const SortOptions = [
        { id: 0, value: 'All', label: 'All' },
        { id: 1, value: 'By Date', label: 'By Date' },
    ];

    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some(ref => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };

    // Dropdown selections of dates
    const handleSelect = (date: any) => {
        const startDate = moment(date.selection.startDate).startOf('day').format('YYYY-MM-DD');
        const endDate = moment(date.selection.endDate).startOf('day').format('YYYY-MM-DD');
        setDate(date)
        // setStartDate(startDate);
        // setEndDate(endDate);
        setTempStartDate(startDate);
        setTempEndDate(endDate);
    };

    const selectionRange = {
        startDate: moment(tempStartDate).toDate(),
        endDate: moment(tempEndDate).toDate(),
        key: 'selection',
    };



    // Handle change for dropdown selection
    const handleChange = (event: any) => {
        setPageNumber(1)
        setSearchTerm('')
        const value = event.label;
        if (value == 'By Date') {
            setTimeout(() => {
                setShowCalendar(true)
                setSelectedOption(value)
                setTempStartDate(new Date())
                setTempEndDate(new Date())
            }, 500)

        } else {
            setSelectedOption(value);
            setShowCalendar(false)
            setStartDate('')
            setEndDate('')
        }
    };
    const handleDropdownClick = (event: any) => {
        // Ensure this only happens when clicking the dropdown, not the calendar
        if (!event.target.closest('.smart-date-picker')) {
            if (showCalendar || showMetricsCalender) {
                setShowCalendar(false);
                setShowMetricsCalender(false)
                setStartDate('');
                setEndDate('');
            }
        }
    };
    // custum selected dates on  submit
    const setCustumDates = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        setShowCalendar(false)

    }
    // cancel the selected dates
    const resetTime = () => {
        setStartDate(" ")
        setEndDate(" ")
        setShowCalendar(false)
        setShowMetricsCalender(false)
    }

    // To open delete resource popup
    const deleteItem = (resourceID: any) => {
        setDeletingIndex(resourceID)
        setShowDeletePopup(true)
        clearActivityPopup()
    }
    // Delete resource success handle
    const deleteSuccessHandle = () => {
        let obj = {
            "resourceId": deletingIndex
        }
        deleteReource(obj).then((response: any) => {
            if (response.status) {
                showToastSuccess(response.message)
                getResurceListData();
                setShowDeletePopup(false)
            } else {
                showToastSuccess(response.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
            setShowDeletePopup(false)
        })
    }
    // To add new resource
    const createResources = () => {
        setSelectedItem('')
        setShowCreateResouce(true)
        setSortValue('')
        setPageNumber(1)
        setSelectedOption('')
        setOrderBy('Asc')
        setSearchTerm('')
        setSelectedUserList('resourceLog')
        setShowCalendar(false)
    }
    // To veiw resource detials
    const viewResource = (resourceId: any) => {
        clearActivityPopup()
        getResourceByID(resourceId).then((response: any) => {
            setShowCreateResouce(false)
            setshowResourceDetails(true)
            setSelectedItem(response?.resourceDetails[0])
        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })

    }
    // To open edit popup and get data by id
    const editresourceItem = (item: any) => {
        clearActivityPopup()
        getResourceByID(item).then((response: any) => {
            if (response.status) {
                setShowCreateResouce(true)
                setSelectedItem(response.resourceDetails[0])
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    // Searchfunctionality
    const handleSearch = (value: any) => {
        setSearchTerm(value);
    };

    useEffect(() => {
        getResurceListData();
    }, [pageNumber, searchTerm, orderBy, selectedOption, startDate, endDate]);
    // Pagination
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPageNumber(value);
    };
    const totalPages = Math.ceil(totalCount / recordsPerPage);
    // To get the resource list
    const getResurceListData = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "search": searchTerm,
            'filterBy': selectedOption,
            "startDate": startDate ? startDate : '',
            "endDate": endDate ? endDate : '',
            "sortColumnName": sortValue,
            "sortColumnDirection": orderBy,
            "utcOffset": (startDate && endDate !== '' ? utcOffset : '')

        }
        getResources(obj).then((response: any) => {
            if (response?.status == true) {
                setResourcesData(response.resourceList[0].ResourceList)
                setTotalCount(response.resourceList[0].TotalCount);
            } else {
                showToastError(response?.message)
            }

        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }
    // Order of all table values  
    const toggleOrder = (type: 'qiscore' | 'upvotes' | 'date' | 'topicNames' | 'usefulness' | 'FlaggedCount' | 'title' | 'bookmarks' | 'pin') => {
        if (type === 'qiscore') {
            const newOrder = orderByQiscore === 'Asc' ? 'Desc' : 'Asc';
            setOrderByQiscore(newOrder);
            setOrderBy(newOrder)
            setSortValue('qiScore')
        } else if (type === 'upvotes') {
            const newOrder = orderByUpvotes === 'Asc' ? 'Desc' : 'Asc';
            setOrderByUpvotes(newOrder);
            setSortValue('upvotesCount')
            setOrderBy(newOrder)
        } else if (type === 'bookmarks') {
            const newOrder = orderByBookmarksCount === 'Asc' ? 'Desc' : 'Asc';
            setOrderByBookmarksCount(newOrder);
            setSortValue('BookMarkCount')
            setOrderBy(newOrder)
        }
        else if (type === 'date') {
            const newOrder = orderByDate === 'Asc' ? 'Desc' : 'Asc';
            setorderByDate(newOrder);
            setSortValue('uploadedDateTime')
            setOrderBy(newOrder)
        }
        else if (type === 'pin') {
            const newOrder = orderByPinRecords === 'Asc' ? 'Desc' : 'Asc';
            setorderByPinRecords(newOrder);
            setSortValue('pinnedResources')
            setOrderBy(newOrder)
        }
        else if (type === 'topicNames') {
            const newOrder = orderBytopicNames === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytopicNames(newOrder);
            setSortValue('topicNames')
            setOrderBy(newOrder)
        }
        else if (type === 'usefulness') {
            const newOrder = orderByusefulness === 'Asc' ? 'Desc' : 'Asc';
            setOrderByusefulness(newOrder);
            setSortValue('usefulness')
            setOrderBy(newOrder)
        }
        else if (type === 'FlaggedCount') {
            const newOrder = orderByFlaggedCount === 'Asc' ? 'Desc' : 'Asc';
            setOrderByFlaggedCount(newOrder);
            setSortValue('FlaggedCount')
            setOrderBy(newOrder)
        }
        else if (type === 'title') {
            const newOrder = orderBytitle === 'Asc' ? 'Desc' : 'Asc';
            setOrderBytitle(newOrder);
            setSortValue('title')
            setOrderBy(newOrder)
        }
    };
    // To add custum filter to calender
    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: 'Custom',
            range: () => ({
                startDate: new Date(),
                endDate: new Date(),
            }),
            isSelected(range: any) {
                return range.custom;
            },
        },
    ]);
    const closeSucess = () => {
        dispatch(setShowAddSuccessPopup(false))

    }
    const listMethod = (listMethod: any) => {
        setSelectedUserList(listMethod)
    }
    // To send the utcoff set time
    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };
    // Metrics filter handle change
    const metricsHandleChange = (event: any) => {
        const value = event.value
        setSelectedSortOption(value); // Set the entire event object
        if (value === 'By Date') {
            setShowMetricsCalender(true);
            setTempStartDate(new Date())
            setTempEndDate(new Date())
        } else {
            setShowMetricsCalender(false);
            setStartDate('');
            setEndDate('');
        }
    }
    // custum selected dates for metrics submit
    const setCustumDatesMetrics = () => {
        let SDates = moment(dates?.selection?.startDate).startOf('day').format('YYYY-MM-DD');
        let EDates = moment(dates?.selection?.endDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(SDates);
        setEndDate(EDates);
        // getContributeListData();
        setShowMetricsCalender(false)
    }
    const pinOrUnpinRecords = (pinstatus: boolean, resourceId: any) => {
        setpinStatus(pinstatus);
        let obj = { resourceID: resourceId, pinStatus: pinstatus };
        pinUnpin(obj)
            .then((response: any) => {
                if (response?.status === true) {
                    getResurceListData();
                } else {
                    showToastError(response?.message);
                }
            })
            .catch(() => {
                showToastError(ErrorMessages.SomethingWrong);
            });
    };
    return (
        <>
            <div className="container-fluid  px-3">
                {!showCreateResouce && !resourceDetials && !showEditInViewSc &&
                    <div>
                        <div className="d-flex justify-content-between align-items-center shadow-sm bg-white rounded border p-3">
                            <h3 className="fw-bold mb-0">Resources </h3>
                            {selectedUserList === 'resourceLog' && <button className="btn btn-primary text-white fs-sm align-items-center d-flex justify-content-between text-dark  py-2" onClick={createResources}><FiPlusCircle className="me-1" size={18} />
                                <div className="h-[16px] text-sm">Add Resource</div>
                            </button>}
                        </div>
                        <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                            <div className="d-flex justify-content-end mt-0 align-items-center">
                                <div className={` p-3 fs-sm py-2 border text-center me-2 w-[160px]  cursor-pointer  rounded ${selectedUserList === 'resourceLog' ? 'bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('resourceLog'); setPageNumber(1); setSelectedOption(''); setSearchTerm(''); setShowCalendar(false) }}> Resource Log</div>
                                <div className={`p-3  fs-sm py-2 border text-center w-[160px] cursor-pointer rounded  ${selectedUserList === 'metrics' ? ' bg-secondary text-white shadow-sm' : ' fw-normal'}`} onClick={() => { listMethod('metrics'); setSelectedSortOption(''); setStartDate(''); setEndDate(''); setShowMetricsCalender(false) }}> Metrics</div>
                            </div>
                            {selectedUserList == 'resourceLog' ?
                                <div className="d-flex justify-content-end mt-0 align-items-center">
                                    <div className="me-2">
                                        <SearchInput label='Search' onChange={handleSearch} value={searchTerm} />
                                    </div>
                                    <div className="position-relative smart-dropdown" onClick={handleDropdownClick}>
                                        <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Filter" />
                                        <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                                        <div style={{ zIndex: '99999' }} className="position-fixed  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                            {showCalendar && (
                                                <div className="" style={{ display: 'contents' }}>
                                                    <DateRangePicker
                                                        ranges={[selectionRange]}
                                                        onChange={handleSelect}
                                                        moveRangeOnFirstSelection={false}
                                                        staticRanges={customStaticRanges}
                                                        inputRanges={[]} // Remove predefined input ranges
                                                        showMonthArrow={true} />
                                                    <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                                        <button className="btn btn-primary-outline py-1 h-[30px]" onClick={resetTime}><span className=" d-inline-block leading-6">Cancel</span></button>
                                                        <button className="btn btn-primary text-white py-1  ms-2  h-[30px]" onClick={setCustumDates}><span className="d-inline-block  leading-6">Submit</span></button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="smart-dropdown position-relative" onClick={handleDropdownClick}>
                                    {/* <Dropdown value={selectedSortOption} options={SortOptions} onChange={metricsHandleChange} placeholder="Filter" /> */}
                                    {/* <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" /> */}
                                    <div style={{ zIndex: '99999' }} className="position-fixed  smart-date-picker deep-shadow bg-white p-0 rounded border mt-[6px]">
                                        {showMetricsCalender && (
                                            <div className="" style={{ display: 'contents' }}>
                                                <DateRangePicker
                                                    ranges={[selectionRange]}
                                                    onChange={handleSelect}
                                                    moveRangeOnFirstSelection={false}
                                                    staticRanges={customStaticRanges}
                                                    inputRanges={[]} // Remove predefined input ranges
                                                    showMonthArrow={true} />
                                                <div className="d-flex justify-content-center position-absolute bottom-0 end-0 py-2  pr-3">
                                                    <button className="btn btn-primary-outline py-1 h-[30px] d-inline-block leading-6" onClick={resetTime}>Cancel</button>
                                                    <button className="btn btn-primary text-white py-1  ms-2  h-[30px] d-inline-block  leading-6" onClick={setCustumDatesMetrics}>Submit</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>}

                        </div>
                        {selectedUserList == 'resourceLog' && (
                            <>
                                <div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
                                    <table className="table position-relative my-0">
                                        <thead>
                                            <tr className="fw-bold  sticky top-0 z-[9]">
                                                <th scope="col" className="w-[300px]">
                                                    <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('title')} title={orderBytitle === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                        <div className="d-flex align-items-center">Resources Title <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /> </div>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('qiscore')} title={orderByQiscore === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}>
                                                        <div className="d-flex align-items-center">QI-Score <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div>
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('upvotes')}>
                                                        <div className="d-flex align-items-center">Upvotes <span title={orderByUpvotes === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span></div>
                                                    </div>
                                                </th>
                                                <th onClick={() => toggleOrder('bookmarks')} title={orderByBookmarksCount === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center  cursor-pointer text-nowrap">Bookmarks <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div> </th>
                                                <th className="w-[200px]" onClick={() => toggleOrder('topicNames')} title={orderBytopicNames === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center cursor-pointer text-nowrap">Topic <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                                <th onClick={() => toggleOrder('usefulness')} title={orderByusefulness === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center  cursor-pointer text-nowrap">Usefulness <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div> </th>
                                                <th onClick={() => toggleOrder('FlaggedCount')} title={orderByFlaggedCount === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'}><div className="d-flex align-items-center  cursor-pointer text-nowrap">Flagged <PiArrowsDownUpFill className="ms-2 mt-[-4px]" size={15} /></div></th>
                                                <th className="text-nowrap">Uploaded By</th>
                                                {/* <th className="text-nowrap">Buddy Status</th> */}
                                                <th className="w-[120px]" scope="col"><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('date')}>
                                                    Date <span title={orderByDate === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                                </div></th>
                                                <th className="w-[100px]" scope="col"><div className='d-flex justify-content-start cursor-pointer align-items-center text-nowrap' onClick={() => toggleOrder('pin')}>
                                                    Pin/Unpin <span title={orderByPinRecords === 'Asc' ? 'Switch to descending order' : 'Switch to ascending order'} className='ms-2'><PiArrowsDownUpFill className="mt-[-4px]" /></span>
                                                </div></th>
                                                <th className="w-[100px]">Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resourcesData && resourcesData?.length > 0 ?
                                                resourcesData?.map((item: any, index: any) => (
                                                    <tr key={item?.resourceId} className={`${resourcesData?.length == 1 ? 'moreoptions-position-fixed' : ''}`}>
                                                        <td className="cursor-pointer  hover:text-primary" onClick={() => viewResource(item?.resourceId)} title={item?.title}>{item?.title?.length > 35 ? item?.title?.slice(0, 35) + '...' : item?.title}</td>
                                                        <td>{item?.qiScore}</td>
                                                        <td> <div className=" d-flex justify-content-start"><BiUpArrowAlt />{item?.upvotesCount}</div> </td>
                                                        <td> <div className=" d-flex justify-content-start"><BiUpArrowAlt />{item?.BookMarkCount}</div> </td>
                                                        <td title={item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ')}>
                                                            {(() => {
                                                                const topics = item?.topicNames?.map((topic: any) => topic?.TopicName).join(', ');
                                                                const maxLength = 20; // Specify the maximum length of the displayed string
                                                                return topics?.length > maxLength ? `${topics?.slice(0, maxLength)}...` : topics;
                                                            })()}
                                                        </td>
                                                        <td>{Math.floor(item.usefulness * 100) / 100}%</td>
                                                        <td>{item.FlaggedCount}</td>
                                                        <td>{item?.uploadedBy}</td>
                                                        {/* <td>
                                                            {item?.status === "Trained" && (
                                                                <span className="badge bg-success font-normal" >{item.status}</span>
                                                            )}
                                                            {item?.status === "Scheduled" && (
                                                                <span className="badge bg-warning font-normal text-dark"  >{item.status}</span>
                                                            )}
                                                            {item?.status === "Failed to process" && (
                                                                <span className="badge bg-danger font-normal" >{item.status}</span>
                                                            )}
                                                            {item?.status === "Not Scheduled" && (
                                                                <span className="badge bg-blue font-normal">{item.status}</span>
                                                            )}
                                                        </td> */}
                                                        <td className="w-[120px]">{formatDates(item?.uploadedDateTime)}</td>
                                                        <td>
                                                            {item?.pinStatus === false ? (
                                                                <BsPinAngle size={16} className="cursor-pointer" onClick={() => pinOrUnpinRecords(true, item?.resourceId)} />
                                                            ) : (
                                                                <BsPinFill size={16} className="cursor-pointer" onClick={() => pinOrUnpinRecords(false, item?.resourceId)}
                                                                />
                                                            )}
                                                        </td>
                                                        <td className="actions-column ">
                                                            <div className="d-flex justify-content-start align-items-center position-relative" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                                                <div className="ml-[20px] cursor-pointer">
                                                                    <FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                                                    <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-108px] w-[120px] shadow-sm mt-[20px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`}>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => viewResource(item?.resourceId)}>View</div>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => editresourceItem(item?.resourceId)}>Edit</div>
                                                                        <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteItem(item?.resourceId)}>Delete</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : <tr>
                                                    <td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6></td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end mt-2 ">
                                    <div className="bg-white rounded p-2 shadow-sm z-[99] z-intial">
                                        <Pagination
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={pageNumber}
                                            onChange={handlePageChange}
                                            siblingCount={1} // Adjust as necessary
                                            boundaryCount={1} // Adjust as necessary
                                            showFirstButton
                                            showLastButton />
                                    </div>
                                </div>

                            </>
                        )}
                        {selectedUserList == 'metrics' && (<ResourceMetrics selectedSortOption={selectedSortOption} startDate={startDate} endDate={endDate} />)}
                    </div>}
            </div>
            <div className="container-fluid px-3">
                {(showCreateResouce || showEditInViewSc) && <CreateResources setShowResource={setShowCreateResouce} selectedItem={selectedItem} setSelectedItem={setSelectedItem} getResurceListData={getResurceListData} setSearchTerm={setSearchTerm} setPageNumber={setPageNumber} setFilterValue={setSelectedOption} setShowEditResource={setShowCreateResouce} setshowResourceDetails={setshowResourceDetails} setShowEditInViewSc={setShowEditInViewSc} />}

                {resourceDetials && <ViewResource selectedItem={selectedItem} setSelectedItem={setSelectedItem} setshowResourceDetails={setshowResourceDetails} searchTerm={setSearchTerm} setPageNumber={setPageNumber} setFilterValue={setSelectedOption} getResurceListData={getResurceListData} setShowEditInViewSc={setShowEditInViewSc} />}

                {deletePopup && <DeleteConformation successHandle={deleteSuccessHandle} actionType='Delete' title='Delete Conformation' closeModal={setShowDeletePopup} />}

                {showAddSuccessPopup && <ResourceAddSuccess title='Your Resource has been published successfully' closeModal={closeSucess} />}

            </div></>

    )
}
