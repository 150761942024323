import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { ErrorMessages } from "../../../constants/Labels";
import { getLoginUserID } from "../../../services/AuthService";
import { approveOrReject } from "../../../services/ContributeService";
import { approveOrRejectModeration } from "../../../services/ModerationService";
import { showToastError, showToastSuccess } from "../../../utils/common/ToastMessages";
import { isNotEmptyString, notAllowSpaces } from "../../../utils/common/Validation";
import TextareaField from "../../ui/TextArea";


interface ApproveProps {
    selectedApproveId?: any
    setshowApprovePopup?: any
    setshowTipDetails?: any
    onReject?: any
    onApprove?: any
    getListData?: any
    tipStatus?: any
    isFromViewPage?: any
    isFromMod?: any
    setSelectedItems?: any
    selectAll?: any

}

const ApproveTipPopup = ({ selectedApproveId, setshowApprovePopup, setSelectedItems, setshowTipDetails, tipStatus, getListData, isFromViewPage, isFromMod, selectAll }: ApproveProps) => {
    const [RejectReason, setRejectReason] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    let userDetails = getLoginUserID();


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        setRejectReason(value);
    }

    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };
    // Approve or reject success handle
    const handleApprovalAction = (id: any) => {
        const newErrors: { [key: string]: string } = {};

        if (!isNotEmptyString(RejectReason)) {
            newErrors.deleteReason = ErrorMessages.ApproveRejectReason;
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if (!isFromMod) {
                let obj = {
                    "tipId": selectedApproveId,
                    "reviewedComments": RejectReason,
                    "approvedBy": userDetails?.userId,
                    "lutipStatusID": id
                }
                approveOrReject(obj).then((response: any) => {
                    if (response?.status) {
                        setshowApprovePopup(false)
                        getListData()
                        showToastSuccess(response?.message)
                        if (isFromViewPage) {
                            setshowTipDetails(false)
                        }
                    } else {
                        showToastError(response?.message)
                        setshowApprovePopup(false)
                    }

                }).catch(() => {
                    showToastError(ErrorMessages?.SomethingWrong)
                    setshowApprovePopup(false)

                })
            } else {
                let obj = {
                    "moderationId": selectedApproveId.toString(),
                    "reviewedComments": RejectReason,
                    "reviewedBy": userDetails?.userId,
                    "statusID": id
                }
                approveOrRejectModeration(obj).then((response: any) => {
                    if (response?.status) {
                        setshowApprovePopup(false)
                        getListData()
                        showToastSuccess(response?.message)
                        setSelectedItems('')
                        if (isFromViewPage) {
                            setshowTipDetails(false)
                        }
                    } else {
                        showToastError(response?.message)
                        setshowApprovePopup(false)
                    }

                }).catch(() => {
                    showToastError(ErrorMessages?.SomethingWrong)
                    setshowApprovePopup(false)

                })
            }


        }
    }

    return (
        <div className="position-fixed  w-100 start-0 top-0 h-100 z-[99999]  ">
            <div className="d-flex align-items-center h-100 bg-dark justify-content-center bg-opacity-50 p-2">
                <div className=" rounded bg-white  shadow-xxl border justify-content-center w-[400px]">
                    <div className='p-4'>
                        <div className="d-flex justify-content-between ">
                            {tipStatus == 1 ? <h4 className=' mb-4 text-start fw-bold'>Reject</h4> : tipStatus == 2 ? <h4 className=' mb-4 text-start fw-bold'>Approve</h4> : tipStatus == 3 ? <h4 className=' mb-4 text-start fw-bold'>Approve / Reject</h4> : null}
                            <RxCross2 size={20} className="cursor-pointer" onClick={() => setshowApprovePopup(false)} />
                        </div>
                        <form>
                            <TextareaField
                                name="approveReason"
                                label="Enter reason"
                                componentName=""
                                value={RejectReason}
                                onChange={handleChange}
                                rows={4}
                                placeholder=""
                                error={errors.deleteReason}
                                onKeyDown={handleKeyDown} />
                            <div className='d-flex justify-content-end align-items-center mt-[32px]'>
                                {tipStatus == 1 ? (<button type="button" className='btn btn-danger w-[105px]'><span className="h-[20px] d-block" onClick={() => { handleApprovalAction(2) }}> Reject</span></button>) : tipStatus == 2 ? (<button type="button" className='btn btn-primary  text-white w-[105px]' ><span onClick={() => { handleApprovalAction(1) }} className="h-[20px] d-block">Approve</span></button>) : tipStatus == 3 ? (
                                    <> <button type="button" className='btn btn-primary  me-2 text-white w-[105px]'><span onClick={() => { handleApprovalAction(1); }} className="h-[20px] d-block">Approve</span></button>
                                    <button type="button" className='btn btn-danger w-[105px]'><span className="h-[20px] d-block" onClick={() => { handleApprovalAction(2); }}> Reject</span></button></>) : null}

                                {selectAll && <button type="button" className='btn btn-primary  text-white w-[105px]' ><span onClick={() => { handleApprovalAction(1) }} className="h-[20px] d-block">Approve All</span></button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ApproveTipPopup