import { Chart } from 'primereact/chart';
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { BiUpArrowAlt } from "react-icons/bi";
import { BsFilterLeft } from "react-icons/bs";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { getPieChart, GetTrendingTips, GetTrendingTopics, timelineChart } from "../../services/ContributeService";
import { showToastError } from "../../utils/common/ToastMessages";

interface popupProps {
    selectedSortOption?: any
    startDate?: any
    endDate?: any
}

export default function ContributeMetrics({ selectedSortOption, startDate, endDate }: popupProps) {
    const [trendingTopics, setTrendingTopics] = useState([])
    const [trendingTips, setTrendingTips] = useState([])
    const [doughnutData, setDoughnutData] = useState<any>()
    const [filterBy, setFilterBy] = useState('M'); // Default to 'Monthwise'
    const [basicData, setBasicData] = useState({});

    const filterOptions = [
        { label: 'Month wise', value: 'M' },
        { label: 'Topic wise', value: 'T' },
    ];

    // Donought values
    const [] = useState({
        labels: ['user Engagement', 'DAU', 'Bounce Rate'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56"
                ]
            }]
    });

    // Donought options
    const [lightOptions] = useState({
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true, // This will use point style for the legend
                    pointStyle: 'circle', // Set point style to circle
                    color: '#495057'
                }
            }
        }
    });

    // graph options
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .6,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'oval',
                    color: '#000',
                    font: {
                        size: 14,
                        lineHeight: 1.5
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#313131',
                    font: {
                        size: 12,
                        weight: 'bold'
                    },
                    stepSize: 1 // Ensure ticks are displayed at integer values
                },
                title: {
                    display: true,
                    padding: { top: 1 }
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    // For users Graphs get API integration 
    const GetTrendingTopicsList = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "startDate": startDate,
            "endDate": endDate,
            "filterBy": selectedSortOption,
            "utcOffset": utcOffset
        }
        GetTrendingTopics(obj).then((response: any) => {
            setTrendingTopics(response?.trendingTipsTopicsData)
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // To send the utcoff set time
    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };

    // TO get trending posts list
    const GetTrendingTipsList = () => {
        const utcOffset = getUTCOffset()
        let obj = {
            "startDate": startDate,
            "endDate": endDate,
            "filterBy": selectedSortOption,
            "utcOffset": utcOffset
        }
        GetTrendingTips(obj).then((response: any) => {
            setTrendingTips(response?.trendingTipsData)
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // Function to fetch chart data from the API
    const fetchChartData = async (filter: any) => {
        let obj = {
            "filterBy": filter,
        }
        timelineChart(obj).then((response: any) => {
            formatChartData(response.timelineChartdata); // Format and set the chart data
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    // Function to format and set chart data
    const formatChartData = (timelineChartdata: any) => {
        const formattedData = {
            labels: timelineChartdata[0].Lables.split(', ').map((label: any) => label.trim()),
            datasets: timelineChartdata[0].datasets.map((dataset: any) => ({
                label: dataset.label,
                data: dataset.data.split(', ').map(Number),
                fill: dataset.fill,
                borderColor: dataset.borderColor,
                tension: parseFloat(dataset.tension),
                backgroundColor: dataset.borderColor
            }))
        };
        setBasicData(formattedData);
    };

    const fetchPieChartData = async () => {
        getPieChart().then((response: any) => {
            formattedData(response); // Format and set the chart data
        })
            .catch((error: any) => {
                showToastError(error);
            });
    };
    const formattedData = (pieChartData: any) => {
        const chartData = pieChartData[0];
        const formattedPieData = {
            labels: chartData.Lables.split(', ').map((label: any) => label.trim()),
            datasets: [
                {
                    data: chartData.datasets[0].data.split(', ').map(Number),
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#C9CBCF', '#36B3EB', '#7B9DFF', '#3A99D9'] // Example colors
                }
            ]
        };

        setDoughnutData(formattedPieData);
    };


    // Fetch the data on component mount and whenever filterBy changes
    useEffect(() => {
        fetchChartData(filterBy);
    }, [filterBy]);

    const handleFilterChange = (e: any) => {
        setFilterBy(e.value);
    };

    useEffect(() => {
        GetTrendingTopicsList()
        GetTrendingTipsList()
        fetchPieChartData()
    }, [selectedSortOption, startDate, endDate])


    return (
        <div className="container-fluid ps-0">
            <div className=" mt-4">
                <div className="row">
                    <div className="col-md-8">
                        <div className="bg-white p-3 shadow-md border rounded-md">
                            <div className="d-flex align-items-center mb-3 justify-content-between">
                                <h4 className="fw-bold d-flex mb-0">Timeline Chart of Usage</h4>
                                <div className="d-flex justify-content-center mb-0 smart-dropdown position-relative">
                                    <Dropdown value={filterBy} options={filterOptions} onChange={handleFilterChange} placeholder="Select Filter"
                                    />
                                    <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                                </div>

                            </div>
                            <Chart type="line" key={filterBy} data={basicData} options={basicOptions} style={{ height: '400px', width: '100%' }} />
                        </div>
                    </div>
                    <div className="col-md-4  bg-white p-3 shadow-md border rounded-md justify-content-end "   >
                        <h4 className="fw-bold d-flex mb-3">Topic Prioritisation</h4>
                        <div style={{ position: 'relative', width: '100%', margin: '0 auto' }}>
                            <Chart type="doughnut" data={doughnutData} options={lightOptions} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-3">
                <div className="row">
                    <div className="col-md-4">
                        <div className="bg-white p-3 shadow-md border rounded-md pb-0 mih-calc-411">
                            <div className="d-flex justify-content-between mb-4">
                                <h4 className="fw-bold mb-0">Trending Topics</h4>
                                <HiMiniArrowTrendingUp className="fw-bold text-secondary" size={20} />
                            </div>
                            <div className="h-calc-100vh-minus-516 overflow-y-auto overflow-x-hidden">
                                {trendingTopics?.length > 0 ? trendingTopics && trendingTopics?.map((item: any, index: any) => (
                                    <div key={index} className="d-flex justify-content-between mb-[10px]">
                                        <h6 className="mb-0 "> {item?.TopicName}</h6>
                                        <p className="d-flex justify-content-between mb-0 fw-bold text-base"> <BiUpArrowAlt className="text-primary pt-0" size={20} /> {item?.TotalUpVotes}</p>
                                    </div>
                                )) : <div className="text-center text-gray h-100 d-flex align-items-center justify-content-center">No data found</div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="bg-white p-3 shadow-md border rounded-md pb-0 mih-calc-411">
                            <div className="d-flex justify-content-between mb-4">
                                <h4 className="fw-bold mb-0 ">Trending Tips</h4>
                                <HiMiniArrowTrendingUp className="fw-bold text-secondary" size={20} />
                            </div>
                            <div className="h-calc-100vh-minus-516 overflow-y-auto  overflow-x-hidden">
                                {trendingTips?.length > 0 ? trendingTips && trendingTips?.map((item: any, index: any) => (
                                    <div key={index} className="d-flex justify-content-between mb-[10px]">
                                        <h6 className="mb-0 " title={item?.Title}>{item?.Title?.length > 80 ? item?.Title?.slice(0, 80) + '...' : item?.Title} </h6>
                                        <p className="d-flex justify-content-between mb-0 fw-bold text-base"> <BiUpArrowAlt className="text-primary pt-0" size={20} /> {item?.TotalUpVotes}</p>
                                    </div>
                                )) : <div className="text-center text-gray h-100 d-flex align-items-center justify-content-center">No data found</div>}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
