
import "./App.css"; // Your custom CSS
import "primereact/resources/themes/lara-light-cyan/theme.css"; // PrimeReact theme (example: lara-light-cyan)
import "primereact/resources/themes/saga-blue/theme.css"; // Another PrimeReact theme (example: saga-blue)
import "primereact/resources/primereact.min.css"; // PrimeReact core styles
// import 'primeflex/primeflex.css';  
import 'primereact/resources/primereact.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import { Provider } from "react-redux";
import { store } from "./redux/Store";
import Navigation from "./navigation/Navigation";
import { ToastContainer } from "react-toastify";
import { PrimeReactProvider } from "primereact/api";
import { useEffect } from "react";
// import './index.css'


const App = () => {
  useEffect(()=>{
    document.cookie = "cookieName=cookieValue; SameSite=Strict; Secure";
  },[])
  return (
    <>
   <PrimeReactProvider>
        <Provider store={store}>
        <Navigation />
          <ToastContainer />
        </Provider>
        </PrimeReactProvider>
    </>
  );
};

export default App;
