import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from "react-redux";
import categories from "../../assets/categories.json";
import imageData from "../../assets/icons.json";
import ConformResSubmit from "../../components/common/modals/ConformResSubmit";
import IconsPopup from "../../components/common/modals/IconsPopupUp";
import InputField from "../../components/ui/Input";
import QuillToolbar, { formats, modules } from "../../components/ui/QuillToolbar";
import { ErrorMessages } from "../../constants/Labels";
import { setResourceId, setShowAddSuccessPopup } from "../../redux/slice/AuthSlice";
import { getLoginUserID } from "../../services/AuthService";
import { CreateResource, UpdateResourceById, deleteReourceFile, getLookUPData, isExistList, getResources, pinUnpin } from "../../services/ResourcesService";
import { showToastError, showToastSuccess, } from "../../utils/common/ToastMessages";
import { isNotEmptyString, notAllowSpaces } from "../../utils/common/Validation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { RiDraggable } from "react-icons/ri";
import { MdDragIndicator } from "react-icons/md";
import { BsFillPinFill, BsPin, BsPinAngle, BsPinFill } from "react-icons/bs";



interface PopupProps {
    setShowResource?: any;
    selectedItem?: any;
    setSelectedItem?: any;
    getResurceListData?: any;
    setSearchTerm?: any
    setPageNumber?: any
    setFilterValue?: any
    setShowEditResource?: any
    setshowResourceDetails?: any
    setShowEditInViewSc?: any
}
interface SensitiveContentTopic {
    SensitiveContentID: number;
    SensitiveContentName: string;
}

export default function CreateResources({ setShowResource, selectedItem, getResurceListData, setSearchTerm, setPageNumber, setFilterValue, setShowEditResource, setshowResourceDetails, setShowEditInViewSc }: PopupProps) {
    const dispatch = useDispatch();
    const urlPattern = /^(https?:\/\/)?((([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-zA-Z\d%_.~+&=]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(#[-a-zA-Z\d_]*)?$/i; //working uat url resource, community, contribute
    let userDetails = getLoginUserID();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [webLink, setWebLink] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [checkedWarnings, setCheckedWarnings] = useState<any>({});
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [Topicoptions, setTopicoptions] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedTagItems, setselectedTagItems] = useState<any>([]);
    const [Tagptions, setTagptions] = useState<any>([]);
    const [inputTagValue, setInputTagValue] = useState("");
    const [sensitiveContentTopics, setSensitiveContentTopics] = useState<SensitiveContentTopic[]>([]);
    const [qiScore, setqiScore] = useState([]);
    const [reviews, setReviews] = useState<any>({});
    const [files, setFiles] = useState<File[]>([]);
    const [showSubmitPopup, setShowSubmitPopup] = useState(false)
    const [resourceFormData, setResourceFormData] = useState()
    const [alreadyExistList, setalreadyExistList] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const [selectedImages, setSelectedImages] = useState<any>([])
    const [pinStatus, setPinStatus] = useState(selectedItem?.pinStatus || false);
    const [pageNumber1, setPageNumber1] = useState(1);
    const [searchTerm1, setSearchTerm1] = useState('')
    const [resourcesData1, setResourcesData1] = useState<any>([])
    const [totalCount1, setTotalCount1] = useState(0);
    const [selectedResourceItems, setSelectedResourceItems] = useState<any>([]);
    const [loading1, setLoading1] = useState(false);
    // const [selectedTopics, setSelectedTopics] = useState<any[]>([]);



    // To get the lookup data  
    const lookupdata = () => {
        getLookUPData().then((response: any) => {
            setTagptions(response[0]?.Tags);
            setTopicoptions(response[0]?.Topics);
            // setSensitiveContentTopics(response[0]?.SensitiveContents);
            const filteredTopics = response[0]?.SensitiveContents.filter((item: any) => item.IsSkip === 0);
            setSensitiveContentTopics(filteredTopics);
            setqiScore(response[0]?.QIIndexes);
            localStorage.setItem('templateNames', JSON.stringify(response[0]?.feedbackTemplate));
        })
            .catch(() => {
                showToastError("Error while getting lookup data");
            });
    };
    // Get lookupdata
    useEffect(() => {
        lookupdata();
    }, []);


    // In edit mode to bind values to the form
    useEffect(() => {
        if (selectedItem) {
            setTitle(selectedItem.title);
            setDescription(selectedItem.description);
            setWebLink(selectedItem.link);

            const selectedTopics = Array.isArray(selectedItem?.topicNames)
                ? selectedItem?.topicNames
                : [];
            setSelectedItems(selectedTopics);

            const selectedTags = Array.isArray(selectedItem?.tagNames)
                ? selectedItem?.tagNames
                : [];
            setselectedTagItems(selectedTags);




            const selectedRes = Array.isArray(selectedItem?.LinkedResourceIds) ? selectedItem?.LinkedResourceIds : [];
            setSelectedResourceItems(selectedRes);
            setResourcesData1([
                ...selectedRes
            ]);

            if (selectedItem && selectedItem.media) {
                // Initialize `files` and `newFiles` with IDs and names
                const files = selectedItem?.media.map((mediaItem: any) => ({
                    name: mediaItem.FileName,
                    Id: mediaItem.ResourceLinkID,
                    Filepath: mediaItem.ResourceLink,
                    RFilePath: mediaItem.ResourceLink,
                    IsNew: 0
                }));
                setFiles(files);

                // Initialize `checkedImages` based on `files`
                setSelectedImages(files);
            } else {
                setFiles([]);
                setSelectedImages([]);
            }
            // Initialize checkedWarnings using the IDs from sensitiveWarnings
            const initialCheckedWarnings: { [key: string]: boolean } = {};
            if (Array.isArray(selectedItem?.sensitiveContentNames)) {
                selectedItem?.sensitiveContentNames.forEach(
                    (content: { SensitiveContentName: string }) => {
                        initialCheckedWarnings[content.SensitiveContentName] = true;
                    }
                );
            }
            setCheckedWarnings(initialCheckedWarnings);
            // To bind reviews  Qi score
            const initialReviews: { [key: string]: number } = {};
            if (selectedItem.qiScore && Array.isArray(selectedItem.qiScore) && selectedItem.qiScore.length > 0) {
                selectedItem.qiScore.forEach(
                    (score: { IndexName: string; Score: number }) => {
                        initialReviews[score.IndexName] = score.Score;
                    }
                );
            }
            setReviews(initialReviews);
        }

    }, []);
    console.log(selectedItems, "$$selectedItems")
    // Form handlechange
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "webLink":
                setWebLink(value);
                break;
            default:
                break;
        }
    };
    const handleDescriptionChange = (
        value: string) => {
        setDescription(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            description: "",
        }));
    };

    // To remove the uploaded file
    const [resourceIdsToDelete, setResourceIdsToDelete] = useState<any[]>([]);

    const removeFile = (index: number, resourceId: any) => {
        if (resourceId) {
            setResourceIdsToDelete((prevIds: any) => [...prevIds, resourceId]);
            setSelectedImages((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
        } else if (selectedItem === '') {
            setSelectedImages((prevFiles: any) => prevFiles.filter((_: any, i: number) => i !== index));
        }
        else {
            setSelectedImages((prevFiles: any) => {
                const updatedFiles = prevFiles.filter((_: any, i: number) => i !== index);
                return updatedFiles;
            });
        }
    };
    const pinOrUnpinRecords = (pinstatus: boolean, resourceId: any) => {
        setPinStatus(pinstatus);
        let obj = { resourceID: resourceId, pinStatus: pinstatus };
        pinUnpin(obj)
            .then((response: any) => {
                if (response?.status === true) {
                    getResurceListData();
                } else {
                    showToastError(response?.message);
                }
            })
            .catch(() => {
                showToastError(ErrorMessages.SomethingWrong);
            });

    };


    const handleImageSelection = (selectedImagesFromPopup: string[]) => {
        setSelectedImages(selectedImagesFromPopup); // Update the state with selected images
        setShowPopup(false);
    };

    // convert resources form data into form data payload
    const submitHandler = (e: any) => {
        e.preventDefault();

        const tagNames = selectedTagItems?.map((item: any) => item.TagName).join(",");
        // const topicNames = selectedItems?.map((item: any) => item.TopicName).join(",");
        const topicNames: any = selectedItems;
        const resourceIds = selectedResourceItems?.map((item: any) => item.resourceId).join(",");

        const sensitiveContentIds = Object.keys(checkedWarnings).filter((key) => checkedWarnings[key]).map((key) =>
            sensitiveContentTopics?.find(
                (topic: SensitiveContentTopic) => topic.SensitiveContentName === key
            )?.SensitiveContentID
        )
            .filter(Boolean);

        const reviewsPayload = qiScore?.map(({ QIIndexScoreID, IndexName }) => {
            const score = reviews[IndexName] ? parseInt(reviews[IndexName], 10) : 0;
            return {
                qiScoreID: QIIndexScoreID,
                score: isNaN(score) ? 0 : score,
            };
        });
        // Taken dummy object as above to validate minim score
        const reviewsminscore = qiScore?.map(({ QIIndexScoreID, IndexName, MinScore }) => {
            const score = reviews[IndexName] ? parseInt(reviews[IndexName], 10) : 0;
            return {
                qiScoreID: QIIndexScoreID,
                score: isNaN(score) ? 0 : score,
                MinScore: MinScore
            };
        });

        const newErrors: { [key: string]: string } = {};
        if (!isNotEmptyString(title)) {
            newErrors.title = ErrorMessages.TitleRequired;
        }
        if (selectedItems?.length === 0) {
            newErrors.topic = ErrorMessages.TopicsRequired;
        }
        // if (selectedResourceItems?.length === 0) {
        //     newErrors.resLink = ErrorMessages.resLink;
        // }
        if (isNotEmptyString(webLink) && !urlPattern.test(webLink)) {
            newErrors.webLink = "Please enter a valid URL";
        }
        const invalidScores = reviewsminscore.filter(({ score, MinScore }) => score < MinScore);
        if (invalidScores.length > 0) {
            newErrors.qiScores = "Minimum review values are required.";
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length != 0) {
            showToastError("Please fill all the mandatory feilds")
        }
        // If there are no errors, creating payload
        if (Object.keys(newErrors).length == 0) {
            setLoading(true);
            if (selectedItem == '') {
                const formData = new FormData();
                formData.append("Title", title ? title : '');
                formData.append("Description", description ? description : '');
                formData.append("Link", webLink ? webLink : '');
                formData.append("UploadedBy", userDetails?.userId);
                formData.append("SensitiveContentIds", sensitiveContentIds ? sensitiveContentIds.join(",") : '0');
                formData.append("TagNames", tagNames ? tagNames : []);
                formData.append("TopicNames", JSON.stringify(topicNames ? topicNames : []));
                formData.append("linkResourceIds", resourceIds ? resourceIds : []);

                // Remove the `RFilePath` and `IsNew` keys from each object
                const cleanedArray = selectedImages.map((item: any) => {
                    const { RFilePath, IsNew, ...rest } = item;
                    return rest;
                });
                formData.append("files", JSON.stringify(cleanedArray));

                formData.append("QIScores", JSON.stringify(reviewsPayload));
                uploadResource(formData);
            } else {
                if (resourceIdsToDelete.length > 0) {
                    resourceIdsToDelete.map(resourceId => {
                        let obj = {
                            "ResourceLinkID": resourceId
                        };
                        return deleteReourceFile(obj).then(() => {
                        })
                            .catch(() => {
                                showToastError("Failed to delete file but you can proceed with updating the resource.");
                            });
                    });
                }
                const formData = new FormData();
                formData.append("Title", title ? title : '');
                formData.append("Description", description ? description : '');
                formData.append("ResourceLink", webLink ? webLink : '');
                formData.append("ModifiedBy", userDetails?.userId);
                formData.append("SensitiveContentNames", sensitiveContentIds?.length > 0 ? sensitiveContentIds.join(",") : '0');
                formData.append("TagNames", tagNames ? tagNames : []);
                formData.append("TopicNames", JSON.stringify(topicNames ? topicNames : []));
                formData.append("ResourceId", selectedItem.resourceId);
                formData.append("linkResourceIds", resourceIds ? resourceIds : []);
                const uniqueFromSecondArray = selectedImages.filter((image: any) =>
                    !files.some((existingFile: any) => existingFile.Filepath === image.Filepath)
                );
                // Remove the `RFilePath` and `IsNew` keys from each object
                const cleanedArray = uniqueFromSecondArray.map((item: any) => {
                    const { RFilePath, IsNew, ...rest } = item;
                    return rest;
                });
                formData.append("files", JSON.stringify(cleanedArray) ? JSON.stringify(cleanedArray) : 'sdadsa');

                formData.append("QIScores", JSON.stringify(reviewsPayload));
                UpdateResource(formData)
            }
        }
    };


    // on click submit button in resource form
    const uploadResource = (formData: any) => {
        setResourceFormData(formData)
        let obj = {
            "title": title,
            "description": description
        }
        isExistList(obj).then((response: any) => {
            if (response.status == true) {
                setShowSubmitPopup(true)
                setalreadyExistList(response?.userResourceDetails)
                setLoading(false);
                return;
            }
            setLoading(false);
            showToastError(response.message);
        })
            .catch(() => {
                setLoading(false);
                showToastError("An error occurred while creating resource");
            });
    };
    // on clik i'm sure submit resource
    const submitResource = () => {
        CreateResource(resourceFormData).then((response: any) => {
            if (response.status == true) {
                dispatch(setResourceId(response?.resourceId));
                dispatch(setShowAddSuccessPopup(true));
                setShowSubmitPopup(false)
                setShowEditResource(false)
                setSearchTerm('')
                setFilterValue('')
                setPageNumber(1)
                getResurceListData()
            }
            else {
                showToastError(response?.message)
                setShowSubmitPopup(false)
            }
        }).catch(() => {
            setLoading(false);
            showToastError("An error occurred while creating resource");
        });
    }

    // APi to update resources by id
    const UpdateResource = (formData: any) => {
        UpdateResourceById(formData).then((response: any) => {
            if (response.status == true) {
                showToastSuccess(response.message);
                setShowResource(false);
                getResurceListData();
                // dispatch(setShowSuccesPopup(true));
                setLoading(false);
                setSearchTerm('')
                setFilterValue('')
                setPageNumber(1)
                setShowEditInViewSc(false)
                setShowEditResource(false)
                return;
            }
            setLoading(false);
            showToastError(response.message);
        })
            .catch(() => {
                setLoading(false);
                showToastError("An error occurred while updating resource");
            });
    };
    // Handle sensitive content change
    const handleSensitiveChange = (event: any) => {
        const { name, checked } = event.target;
        setCheckedWarnings((prevTopics: any) => ({
            ...prevTopics,
            [name]: checked,
        }));
    };
    // for resource Review points

    const handleReviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = event.target.value;
        const maxValue = Number(event.target.max);
        const minValue = Number(event.target.min);
        // Check if the entered value is a valid number
        const numericValue = Number(enteredValue);
        if (isNaN(numericValue)) {
            showToastError('Please enter a valid value');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
            return;
        }

        // Check if the entered value is an integer
        if (!Number.isInteger(numericValue)) {
            showToastError('Please enter valid Round off value');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
            return;
        }

        // Check if the entered value is within the valid range
        if (numericValue > maxValue) {
            showToastError('Value cannot exceed the maximum score');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
        } else if (numericValue < minValue) {
            showToastError('Value cannot be less than the minimum score');
            event.target.value = '';
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [event.target.name]: ''
            }));
        } else {
            const { name } = event.target;
            setReviews((prevReviews: any) => ({
                ...prevReviews,
                [name]: enteredValue,
            }));
        }
    };


    // Handlers for Tag and Topic changes
    const handleTagChanges = (e: any) => {
        const selectedTags = e.value.map((tagName: string) =>
            Tagptions?.find((tag: any) => tag?.TagName === tagName)
        );
        setselectedTagItems(selectedTags);
    };
    // Handle change to set selected topics
    // const handleTopicChanges = (e: any) => {
    //     const selectedTopics = e.value.map((topicName: string) =>
    //         Topicoptions?.find((topic: any) => topic?.TopicName === topicName)
    //     );
    //     setSelectedItems(selectedTopics);
    // };

    // To show newly added Topic
    const handleAddNewItem = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputValue)) {
            event.preventDefault(); // Prevent default behavior
            if (inputValue && !(Topicoptions ?? []).some((option: any) => option.TopicName === inputValue)) {
                const newTopic = { TopicId: " ", TopicName: inputValue };
                setTopicoptions([...(Topicoptions ?? []), newTopic]);
                setSelectedItems([...selectedItems, newTopic]);
                setInputValue("");
            }
        }
    };
    // To show newly added tag
    const handleAddNewTag = (event: React.KeyboardEvent) => {
        if ((event.key === "Enter" || event.key === "Tab") && isNotEmptyString(inputTagValue)) {
            event.preventDefault(); // Prevent default behavior
            if (
                inputTagValue &&
                !(Tagptions ?? []).some((option: any) => option.TagName === inputTagValue)
            ) {
                const newTag = { TagId: " ", TagName: inputTagValue };
                setTagptions([...(Tagptions ?? []), newTag]);
                setselectedTagItems([...selectedTagItems, newTag]);
                setInputTagValue("");
            }
        }
    };
    // Template to add new topics
    const headerTemplate = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddNewItem}
                    placeholder="Type and press enter to add new topic"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };
    // Template to add new tags
    const headerTemplateTag = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    value={inputTagValue}
                    onChange={(e) => setInputTagValue(e.target.value)}
                    onKeyDown={handleAddNewTag}
                    placeholder="Type and press enter to add new tags"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };

    const headerTemplateResource = () => {
        return (
            <div className="p-multiselect-header">
                <input
                    type="text"
                    onChange={onFilter}
                    placeholder="Search to Link Resources"
                    className="p-multiselect-filter form-control"
                />
            </div>
        );
    };

    // TO avoid empty spaces cursor moving
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        notAllowSpaces(event.currentTarget.value, event);
    };
    const selectedItemsTooltip = useRef(null);
    const openIconPopup = () => {
        setShowPopup(true)
    }

    const closePopup = () => {
        setShowPopup(false);
    };
    const cancelClick = () => {
        setShowResource(false);
        // setSelectedItem();
        setShowEditResource(false)
        if (selectedItem !== '') {
            setShowEditResource(false)
            setshowResourceDetails(false)
            setShowEditInViewSc(false)
        }
    };

    const getResurceListData1 = (page: any, searchTerm: any) => {
        setLoading1(true);
        const utcOffset = getUTCOffset()
        let obj = {
            "pageNumber": page,
            "pageSize": 50,
            "search": searchTerm,
            'filterBy': '',
            "startDate": '',
            "endDate": '',
            "sortColumnName": '',
            "sortColumnDirection": 'Asc',
            "utcOffset": ''

        }
        getResources(obj).then((response: any) => {
            if (response?.status == true) {
                //setResourcesData1(response.resourceList[0].ResourceList);
                if (response.resourceList[0].TotalCount > 0) {

                    const newResources = response.resourceList[0].ResourceList.filter(
                        (resource: any) => !selectedResourceItems.some((item: any) => item.resourceId == resource.resourceId)
                    );
                    setResourcesData1([
                        ...selectedResourceItems,
                        ...newResources,
                    ]);
                }
                else {
                    setResourcesData1([
                        ...selectedResourceItems
                    ]);
                }
                setTotalCount1(response.resourceList[0].TotalCount);
            } else {
                showToastError(response?.message)
            }
            setLoading1(false);
        }).catch(() => {
            showToastError(ErrorMessages.SomethingWrong);
            setLoading1(false);
        })
    };

    const onFilter = (e: any) => {
        const input = e.target.value.trim();
        if (input.length >= 3) {
            //   setQuery(input);
            setSearchTerm1(input)
            getResurceListData1(pageNumber1, input);
        }
    };


    // To send the utcoff set time
    const getUTCOffset = () => {
        const date = new Date();
        const offsetInMinutes = date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const offsetMinutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes <= 0 ? '+' : '-';
        return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
    };

    // Handle change to set selected topics
    const handleTopicChangesResource = (e: any) => {
        const selectedTopics = e.value.map((title: string) => {
            return resourcesData1?.find((topic: any) => topic?.title === title)
        }
        );
        if (selectedTopics) {
            console.log("I am in", selectedResourceItems)
            setSelectedResourceItems(selectedTopics)
        }

    };

    // Handler to load more items when scrolling down
    const loadMore = () => {
        if (!loading) {
            setPageNumber1((prevPage) => {
                const newPage = prevPage + 1;
                getResurceListData1(newPage, searchTerm1);
                return newPage;
            });
        }
    };
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedTopics = Array.from(selectedItems);
        const [removed] = reorderedTopics.splice(result.source.index, 1);
        reorderedTopics.splice(result.destination.index, 0, removed);

        setSelectedItems(
            reorderedTopics.map((topic: any, index: any) => ({
                ...topic,
                orderByTopicID: index + 1,
            }))
        );
    };
    const handleTopicChanges = (e: any) => {
        const selected = e.value.map((topic: string) => {
            if (!selectedItems.some((item: any) => item.TopicName === topic)) {
                return {
                    TopicName: topic,
                    orderByTopicID: selectedItems.length + 1,
                };
            }
            return null;
        }).filter(Boolean);

        setSelectedItems((prev) => [...prev, ...selected]); // Use `setSelectedItems` here
    };

    const handleRemoveTopic = (topicName: string) => {
        setSelectedItems((prev) =>
            prev
                .filter((topic: any) => topic.TopicName !== topicName)
                .map((topic: any, index: any) => ({
                    ...topic,
                    orderByTopicID: index + 1,
                }))
        );
    };


    return (
        <div className="mb-2">
            <div className="bg-white rounded shadow-sm p-3 h-100 overflow-y-auto ">
                <div className="d-flex justify-content-start">
                    {selectedItem == "" ? (
                        <h3 className="fw-bold">Add Resources</h3>
                    ) : (
                        <div className="d-flex">
                            <h3 className="fw-bold me-4">Update Resources</h3>
                            <span className="">{!pinStatus ? (
                                <BsPinAngle size={18} className="cursor-pointer mt-1" onClick={() => pinOrUnpinRecords(true, selectedItem?.resourceId)} />
                            ) : (
                                <BsPinFill size={18} className="cursor-pointer mt-1" onClick={() => pinOrUnpinRecords(false, selectedItem?.resourceId)}
                                />
                            )} </span>
                        </div>
                    )}
                </div>
                <form onSubmit={submitHandler}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="position-relative mb-3 h-49">
                                <InputField
                                    name="title"
                                    label="Title"
                                    value={title}
                                    placeholder="Enter your Resource Title here "
                                    onChange={handleChange}
                                    error={errors.title}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            {/* <div className="form-group position-relative mb-3 ">
                                <label className="form-group ">Add Topic</label>
                                <div className="smart-multiselect position-relative group">
                                    <MultiSelect
                                        value={selectedItems?.map((item: any) => item?.TopicName)}
                                        options={Topicoptions?.map((option: any) => ({
                                            label: option?.TopicName,
                                            value: option?.TopicName,
                                        }))}
                                        onChange={handleTopicChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplate}
                                        style={{ width: "100%" }}
                                        placeholder="Select Topics"
                                        tooltipOptions={{ event: 'hover', position: 'bottom' }} // Tooltip options
                                        ref={selectedItemsTooltip}
                                       

                                    />

                                    {errors.topic && (
                                        <div className="error text-danger mt-5">{errors?.topic}</div>
                                    )}
                                </div>
                            </div> */}

                            <div className="form-group position-relative mb-3">
                                <label className="form-group">Add Topic</label>
                                <div className="smart-multiselect position-relative group mb-2">
                                    <MultiSelect
                                        value={selectedItems.map((item: any) => item.TopicName)}
                                        options={Topicoptions.map((option: any) => ({
                                            label: option.TopicName,
                                            value: option.TopicName,
                                        }))}
                                        onChange={handleTopicChanges}
                                        panelHeaderTemplate={headerTemplate}
                                        filter
                                        display="chip"
                                        placeholder="Select Topics"
                                        style={{ width: "100%" }}
                                        selectedItemTemplate={() => ""}
                                    />
                                    {errors.topic && (
                                        <div className="error text-danger mt-5">{errors?.topic}</div>
                                    )}
                                </div>
                                {selectedItems.length > 0 &&
                                    <div className="selected-topics mt-3">
                                        <label className="form-group">Selected Topics</label>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="selected-topics">
                                                {(provided: any) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="d-flex flex-column"
                                                    >
                                                        {selectedItems.map((topic: any, index: any) => (
                                                            <Draggable
                                                                key={topic.TopicName}
                                                                draggableId={topic.TopicName}
                                                                index={index}
                                                            >
                                                                {(provided: any) => (
                                                                    <div
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                        className="topic-item d-flex align-items-center mb-2 p-2 border rounded"
                                                                    >
                                                                        <span className="drag-handle mr-2"><MdDragIndicator size={15} /></span>
                                                                        <span className="topic-name flex-grow-1">
                                                                            {topic.TopicName}
                                                                        </span>
                                                                        <RxCross2 size={20} className="cursor-pointer text-danger" onClick={() => handleRemoveTopic(topic.TopicName)} />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                }

                            </div>

                            <div className="form-group position-relative mb-3">
                                <label className="form-group ">Add Tag</label>
                                <div className="smart-multiselect">
                                    <MultiSelect
                                        value={selectedTagItems?.map((item: any) => item?.TagName)}
                                        options={Tagptions?.map((option: any) => ({
                                            label: option?.TagName,
                                            value: option?.TagName,
                                        }))}
                                        onChange={handleTagChanges}
                                        filter
                                        display="chip"
                                        panelHeaderTemplate={headerTemplateTag}
                                        style={{ width: "100%" }}
                                        placeholder="Select Tags"
                                        tooltipOptions={{ event: 'hover', position: 'bottom' }} // Tooltip options
                                        ref={selectedItemsTooltip}
                                    />
                                    {errors.tags && (
                                        <div className="error text-danger mt-1">{errors.tags}</div>
                                    )}
                                </div>
                            </div>
                            <div className="position-relative mb-3">
                                <InputField
                                    name="webLink"
                                    label="Add Link"
                                    value={webLink}
                                    placeholder="Web link "
                                    onChange={handleChange}
                                    error={errors.webLink}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="position-relative mb-0 ">
                                <div className="form-group position-relative mb-3 textarea-h-232">
                                    <label className='text-dark'>Description</label>
                                    <QuillToolbar />
                                    <div className="" >
                                        <ReactQuill
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            placeholder="Enter your Description here"
                                            modules={modules}
                                            formats={formats}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">

                        </div>
                        <div className="col-md-12">

                        </div>
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="d-flex justify-content-start fw-bold mb-0">Select Icons</h4>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">

                                    <div className="rounded-md p-3 border-dotted-solidate h-[80px]" onClick={openIconPopup}>
                                        <div className="form-group position-relative mb-0 d-flex justify-content-center h-100 align-items-center">
                                            <button className="btn btn-primary px-4 text-white" type="button" >Select Icons</button>
                                        </div>
                                    </div>
                                    <div className="text-danger text-xs ">
                                        {errors.files}
                                    </div>
                                </div>

                                {selectedImages && selectedImages.length > 0 &&
                                    <div className="col-md-12">
                                        {selectedImages?.length > 0 && <div className="text-lg d-flex justify-content-start font-semibold align-items-center ">Selected Icons: <span className="font-normal text-primary ps-1 text-lg d-inline-block">{selectedImages?.length || selectedItem?.media?.length}</span></div>
                                        }
                                        <div className="border px-1 pt-2 pb-0 rounded-md  max-h-[200px] overflow-y-auto">
                                            <div className="row mx-0">
                                                {selectedImages.length > 0 && selectedImages?.map((file: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        className="col-md-1 mb-2 px-1"
                                                    >
                                                        <div className="rounded-md p-2 d-flex flex-column  shadow-md border">
                                                            <div className="d-flex justify-content-end mb-2">
                                                                <div className="w-[16px] h-[16px] flex-16  max-w-[18px] bg-secondary bg-opacity-25 rounded-circle d-flex align-items-center justify-content-center">
                                                                    <RxCross2
                                                                        className=""
                                                                        size={10}
                                                                        onClick={() => removeFile(index, file.Id)}
                                                                        style={{ cursor: "pointer" }}
                                                                    />

                                                                </div>
                                                            </div>
                                                            <img src={file?.RFilePath} alt="images" className="w-[25px] h-[25px] object-contain d-flex  mx-auto" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div
                                className="position-relative mb-2 mt-3 p-3 border rounded "

                            >
                                <h4 className="d-flex justify-content-start fw-bold mb-0">
                                    {" "}
                                    Sensitive Warning{" "}
                                </h4>
                                <p className="mb-2 text-gray">
                                    Would you consider this to be a sensitive topic and likely cause
                                    upset? If so we'd appreciate if you let others know.{" "}
                                </p>
                                <div className="d-flex p-2 row">
                                    {sensitiveContentTopics?.map((topic: any) => (
                                        <div className="col-md-3 mb-2 px-1">
                                            <label key={topic.SensitiveContentID} className="bg-white border shadow-sm me-0 rounded d-flex align-items-center p-[12px]">
                                                <input
                                                    className="text-dark form-check-input w-h-20 cursor-pointer mt-0 "
                                                    type="checkbox"
                                                    name={topic.SensitiveContentName}
                                                    onChange={handleSensitiveChange}
                                                    checked={
                                                        checkedWarnings[topic.SensitiveContentName] || false
                                                    } // Use topic.name for checkedWarnings
                                                />{" "}
                                                <div className="fs-sm ms-1 h-[16px]"> {topic.SensitiveContentName}</div>
                                            </label>
                                        </div>
                                    ))}
                                    {errors.SensitiveContent && (
                                        <div className="error text-danger bottom-minus-18 ps-0">{errors.SensitiveContent}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="position-relative mb-1 mt-3 p-3 rounded border">
                                <h4 className="d-flex justify-content-start fw-bold mb-3">Review</h4>
                                <div className="max-h-48 overflow-y-auto overflow-x-hidden">
                                    <div className="w-calc-3px h-100">
                                        <div className="mb-2 d-flex row">
                                            {qiScore && qiScore?.map(({ IndexName, MaxScore, MinScore }) => (
                                                <div className="col-md-3 mb-2">
                                                    <label className="m-0 border p-2 w-100 rounded bg-solitude">
                                                        <div className="w-100 d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center me-1">
                                                                <span className="d-inline-block me-1">{IndexName}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center ">
                                                                <div className="d-flex justify-content-between text-sm"><span>Min<span className="fw-bold text-dark me-0 text-primary">-{MinScore}</span></span><span className="mx-1 d-inline-block">,</span><span>Max<span className="fw-bold text-dark">-{MaxScore}</span></span></div>
                                                                <input
                                                                    type="number"
                                                                    name={IndexName}
                                                                    min={MinScore}
                                                                    max={MaxScore}
                                                                    onChange={handleReviewChange}
                                                                    value={reviews[IndexName] || ""}
                                                                    style={{ marginLeft: "0px", minWidth: "30px" }}
                                                                    className="ms-2 text-center fs-sm fw-bold input-number p-2 pt-6 form-control h-[30px] w-[120px]"
                                                                    onKeyDown={handleKeyDown}
                                                                />{" "}
                                                                <span className="d-flex px-1 pt-1"> / </span>
                                                                <div className="fs-sm fw-bold  pt-1">{MaxScore}</div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            ))}
                                            {errors.qiScores && (
                                                <div className="error text-danger bottom-minus-18 ps-0 mt-2">{errors.qiScores}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="position-relative mb-1 mt-3 p-3 rounded border">
                                <h4 className="d-flex justify-content-start fw-bold mb-3">Link Resource</h4>
                                <div className="max-h-48 overflow-y-auto overflow-x-hidden">
                                    <div className="w-calc-3px h-100">
                                        <div className="mb-2 d-flex row">

                                            <div className="form-group position-relative mb-3 ">

                                                <div className="smart-multiselect position-relative group">
                                                    <MultiSelect
                                                        value={selectedResourceItems?.map((item: any) => item?.title)}
                                                        options={resourcesData1?.map((option: any) => ({
                                                            label: option?.title,
                                                            value: option?.title,
                                                        }))}
                                                        filterBy="label"
                                                        onChange={handleTopicChangesResource}
                                                        filter

                                                        display="chip"
                                                        panelHeaderTemplate={headerTemplateResource}
                                                        style={{ width: "100%" }}
                                                        placeholder="Select Resources to Link"
                                                        tooltipOptions={{ event: 'hover', position: 'bottom' }} // Tooltip options
                                                        ref={selectedItemsTooltip}
                                                    />

                                                    {/* {errors.resLink && (
                                                        <div className="error text-danger mt-5">{errors?.resLink}</div>
                                                    )} */}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="position-relative mb-2 mt-3 p-3 border rounded " >
                            <h4 className="d-flex justify-content-start fw-bold mb-0">
                                {" "}
                                User Types{" "}
                            </h4>
                            <div className="d-flex p-2 row">
                                {sensitiveContentTopics?.map((topic: any) => (
                                    <div className="col-md-3 mb-2 px-1">
                                        <label key={topic.SensitiveContentID} className="bg-white border shadow-sm me-0 rounded d-flex align-items-center p-[12px]">
                                            <input
                                                className="text-dark form-check-input w-h-20 cursor-pointer mt-0 "
                                                type="checkbox"
                                                name={topic.SensitiveContentName}
                                                onChange={handleSensitiveChange}
                                                checked={
                                                    checkedWarnings[topic.SensitiveContentName] || false
                                                } // Use topic.name for checkedWarnings
                                            />{" "}
                                            <div className="fs-sm ms-1 h-[16px]"> {topic.SensitiveContentName}</div>
                                        </label>
                                    </div>
                                ))}
                                {errors.SensitiveContent && (
                                    <div className="error text-danger bottom-minus-18 ps-0">{errors.SensitiveContent}</div>
                                )}
                            </div>
                        </div> */}

                        <div className="d-flex justify-content-end mt-5">
                            <button
                                className="btn btn-primary-outline me-2 px-4"
                                type="button"
                                onClick={cancelClick}
                            >
                                Cancel
                            </button>
                            {selectedItem == "" ? (
                                <button className="btn btn-primary px-4 text-white" type="submit" disabled={loading}> Submit  </button>
                            ) : (
                                <button className="btn btn-primary px-4 text-white" type="submit" disabled={loading}>   Update </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>

            {showSubmitPopup && <ConformResSubmit successHandle={submitResource} setShowSubmitPopup={setShowSubmitPopup} alreadyExistList={alreadyExistList} />}
            {showPopup && <IconsPopup images={imageData} onClose={closePopup} handleSubmit={handleImageSelection} categories={categories} selectedImages={selectedImages}
            />}
        </div>

    );
}
