import Pagination from "@mui/material/Pagination/Pagination";
import { useCallback, useEffect, useRef, useState } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsFilterLeft } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AddUser from "../../components/common/modals/AddUser";
import DeleteConformation from "../../components/common/modals/DeleteConformation";
import UserServeyPopup from "../../components/common/modals/UserServeyPopup";
import SearchInput from "../../components/ui/SearchInput";
import { fetchImages } from "../../constants/HandleImages";
import { ErrorMessages } from "../../constants/Labels";
import { setloggedInUserNameUpdate } from "../../redux/slice/AuthSlice";
import { getUserList } from "../../services/UserManagementService";
import { showToastError } from "../../utils/common/ToastMessages";
import AdminList from "./AdminList";


export default function UserManagement() {
    const [selectedOption, setSelectedOption] = useState('Active');
    const [ShowInviteUserPopup, setShowInviteUserPopup] = useState(false)
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [searchTerm, setSearchTerm] = useState('')
    const [usersData, setusersData] = useState([])
    const [deletePopup, setShowDeletePopup] = useState(false)
    const [deletingIndex, setDeletingIndex] = useState<any>()
    const [selectedUserList, setSelectedUserList] = useState('users')
    const [serveyPopup, setShowServeyPopup] = useState(false);
    const [userListType, setUserListType] = useState(2)
    const [totalCount, setTotalCount] = useState(0);
    const recordsPerPage = 10; // Number of records per page
    const [selectedUser, setSelectedUser] = useState('')
    const [actionIndex, setActionIndex] = useState<number | null>(null);
    const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const userDetailsName = useSelector((item: any) => item?.userBucket?.loggedInUserNameUpdate)
    const [addUser , setAddUser] = useState(false)


    const dispatch = useDispatch();

    // To clear activity popup
    const clearActivityPopup = useCallback(() => {
        setActionIndex(null);
    }, []);
    // handle clikoutside action popup
    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRefs.current.some(ref => ref && ref.contains(event.target as Node))) {
            return;
        }
        clearActivityPopup();
    }, [clearActivityPopup]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    // To open actions 
    const OpenActionArea = (index: number) => {
        setActionIndex(index);
    };
    // Appusers clik functionality 
    const appUsers = () => {
        setUserListType(2);
        setSelectedOption('Active');
        setSearchTerm('')
        setPageNumber(1);
    }
    // Admins clik functionality 
    const adminUsers = () => {
        setUserListType(1);
        setPageNumber(1);
        setSelectedOption('Active');
        setSearchTerm('')
    }

    // Dropdown values
    const options = [
        { value: 'All', label: 'All' },
        { value: 'Active', label: 'Active Users' },
        { value: 'Inactive', label: 'Inactive Users' },
    ];

    // To open delete resource popup
    const deleteItem = (id: any) => {
        setDeletingIndex(id)
        setShowDeletePopup(true)
    }
    const handleChange = (event: any) => {
        const value = event.value;
        setSelectedOption(value);
        clearActivityPopup()
        setPageNumber(1)
    }
    // To show add new popup
    const inviteUser = () => {
        setShowInviteUserPopup(true)
        setSearchTerm('')
        setPageNumber(1)
        setSelectedUser('')
        setAddUser(true)
    }
    //   Search functionality
    const handleSearch = (e: any) => {
        setSearchTerm(e)
    }
    // Pagination 
    const handlePageChange = (event: any, value: any) => {
        setPageNumber(value);
    }
    const totalPages = Math.ceil(totalCount / recordsPerPage);

    // Get userlist list by page number
    const getUserListData = () => {
        let obj = {
            "pageNumber": pageNumber,
            "pageSize": 10,
            "search": searchTerm,
            "userType": userListType,
            "filter": selectedOption
        }
        getUserList(obj).then((response: any) => {
            if (response?.status == true) {
                setusersData(response.userList[0].UserDetails)
                setTotalCount(response.userList[0].TotalUsers);
                dispatch(setloggedInUserNameUpdate(false))

            } else {
                showToastError(response?.message)
            }

        }).catch((error: any) => {
            showToastError(ErrorMessages.SomethingWrong)
        })
    }

    useEffect(() => {
        getUserListData()
    }, [selectedOption, searchTerm, userListType, pageNumber, userDetailsName])
    // To open servey popup
    const servey = () => {
        setShowServeyPopup(true)
    }

    const listMethod = (listMethod: any) => {
        setSelectedUserList(listMethod)
    }

    const editUser = (item: any) => {
        setSelectedUser(item)
        setShowInviteUserPopup(true)
    }

    return (
        <div className="container-fluid px-3">
            <div className="d-flex justify-content-between mt-0 w-100">
                <div className="bg-white rounded shadow-sm w-100 d-flex justify-content-between align-items-center p-3 z-intial h-[68px]">
                    <h3 className="fw-bold mb-0">User Management </h3>
                    {selectedUserList === 'admin' &&
                        <div className="d-flex justify-content-end ">
                            {/* <button className="btn  btn-outline-dark d-flex justify-content-between me-3 btn-md" onClick={servey} >Survey </button> */}
                            <button className="btn  btn-primary d-flex justify-content-center align-items-center text-white  w-[100px]" onClick={inviteUser} ><AiOutlineUsergroupAdd size={22} className="text-white" /><div className="h-[16px] text-sm">Invite</div> </button>
                        </div>
                    }

                </div>
            </div>
            <div className="d-flex justify-content-between  my-3 bg-white rounded p-2 resourcelist-tab-panel">
                <div className="d-flex justify-content-end mt-0 align-items-center">
                    <button className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedUserList === 'users' ? ' bg-secondary text-white shadow-sm ' : '  fw-normal'}`} onClick={() => { appUsers(); listMethod('users'); }}> App Users</button>
                    <button className={` p-3 fs-sm py-2 border text-center me-2 w-[160px] cursor-pointer  rounded ${selectedUserList === 'admin' ? ' bg-secondary text-white shadow-sm ' : '  fw-normal'}`} onClick={() => { adminUsers(); listMethod('admin') }} > Admin</button>
                </div>
                <div className="d-flex justify-content-end mt-0 align-items-center">
                    <div className="me-2">
                        <SearchInput label='Search' onChange={handleSearch} value={searchTerm} />
                    </div>
                    <div className="position-relative smart-dropdown">
                        <Dropdown options={options} onChange={handleChange} value={selectedOption} placeholder="Select an option" />
                        <BsFilterLeft className="position-absolute start-0 top-50 translate-y-[-50%] text-gray ms-2" />
                    </div>
                </div>
            </div>

            {selectedUserList == 'users' &&
                <div className="smart-table overflow-x-auto shadow-sm border rounded mxh-calc-321">
                    <table className="table table position-relative my-0">
                        <thead className="th-pattenblue">
                            <tr className="fw-bold sticky top-0 z-[9] bg-white">
                                 <th className="w-[400px]">User Name</th>
                                 <th className="w-[400px]"> Email</th>
                                <th>Status </th>
                                <th>Action </th>

                            </tr>
                        </thead>
                        <tbody>
                            {usersData?.length > 0 ?
                                usersData?.map((item: any, index: any) => (
                                    <tr key={index} className={`${usersData?.length == 1 ? 'moreoptions-position-fixed  ' : ''}`}>
                                      <td className="w-[400px]"><div className="text-truncate w-[400px]">{item.Name}</div></td>
                                      <td className="w-[400px]"><div className="text-truncate w-[400px]">{item.Email}</div></td>
                                        <td className="text-start ps-4">
                                            <div className="pl-[20px]">
                                            {(item.IsActive == true && item?.AppAccess == true) ? <img src={fetchImages.activeImg} className="" height={12} width={12} title="Active" /> : <img src={fetchImages.inactiveImg} className="" height={12} width={12} title="Inactive" />}
                                            </div>
                                        </td>

                                        <td className="actions-column dropdown-with-two-options">
                                            <div className="d-flex justify-content-between align-items-center" ref={(el: any) => (dropdownRefs.current[index] = el)}>
                                            {(item.IsActive == true && item?.AppAccess == true)  ?
                                                    <div className="pl-[20px] position-relative"><FaEllipsisV className="font-size-10 cursor-pointer" onClick={() => OpenActionArea(index)} />
                                                        <div className={`custom-dropdown-menu bg-white position-absolute border ml-[-108px] w-[120px] shadow-lg mt-[18px] top-0 z-[9] ${actionIndex === index ? '' : 'd-none'}`} >
                                                            <div className="dropdown-item cursor-pointer py-2" onClick={() => editUser(item)}>Edit</div>
                                                            <div className="dropdown-item cursor-pointer py-2" onClick={() => deleteItem(item.UserID)}>Delete</div>
                                                        </div></div>
                                                    : ' '}
                                            </div>
                                        </td>
                                    </tr>


                                )) : <tr><td colSpan={12}><h6 className="fw-bold mt-3 d-flex justify-content-center">No data found </h6> </td></tr>}
                        </tbody>
                    </table>
                </div>
            }
            {/* Admin user list tab */}
            {selectedUserList == 'admin' && (<AdminList usersData={usersData} getUserListData={getUserListData} />)}
            {usersData?.length > 0 &&
                <div className="d-flex justify-content-end mt-2">
                    <div className="bg-white rounded p-2 shadow-sm">
                        <Pagination
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            page={pageNumber}
                            onChange={handlePageChange}
                            siblingCount={1} // Adjust as necessary
                            boundaryCount={1} // Adjust as necessary
                            showFirstButton
                            showLastButton />
                    </div>
                </div>
            }

            {ShowInviteUserPopup && <AddUser setShowInviteUserPopup={setShowInviteUserPopup} getUserListData={getUserListData} selectedUser={selectedUser} addUser={addUser}/>}

            {deletePopup && <DeleteConformation deleteIndex={deletingIndex} actionType='DeleteUser' title='Delete Conformation' closeModal={setShowDeletePopup} getUserListData={getUserListData} setShowDeletePopup={setShowDeletePopup} />}

            {serveyPopup && <UserServeyPopup setShowServeyPopup={setShowServeyPopup} />}

        </div >


    )
}